const initialState = {
  widgetCodeModal: {
    id: null,
    file_key: null,
    isOpen: false,
    lang: "en",
  },
  widgetDeleteModal: {
    id: null,
    isOpen: false,
  },
  widgetPreviewModal: {
    id: null,
    file_key: null,
    isOpen: false,
    lang: "en",
  },
};

const videoCall = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_VIDEO_CALL_CODE_MODAL":
      return {
        ...state,
        widgetCodeModal: {
          id: action.payload.id,
          file_key: action.payload.file_key,
          isOpen: action.payload.isOpen,
          lang: action.payload.lang ? action.payload.lang : "en",
        },
      };
    case "TOGGLE_VIDEO_CALL_DELETE_MODAL":
      return {
        ...state,
        widgetDeleteModal: {
          id: action.payload.id,
          isOpen: action.payload.isOpen,
        },
      };
    case "TOGGLE_VIDEO_CALL_PREVIEW_MODAL":
      return {
        ...state,
        widgetPreviewModal: {
          id: action.payload.id,
          file_key: action.payload.file_key,
          isOpen: action.payload.isOpen,
          lang: action.payload.lang ? action.payload.lang : "en",
        },
      };

    default:
      return { ...state };
  }
};
export default videoCall;
