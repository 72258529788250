import axios from "axios";
import toast from "react-hot-toast";

const loading = class {
  constructor() {
    this.finishCount = 0;
    this.callback = null;
    this.request = 0;
    this.response = 0;
    this.pathname = null;
    this.loading = false;
  }

  requestCount() {
    if (this.pathname) {
      ++this.request;
      // console.log("count ", this.request, " - ", this.response)
      this.runCallback();
      this.detectFinish();
    }
  }

  responseCount() {
    if (this.pathname) {
      ++this.response;
      // console.log("count ", this.request, " - ", this.response)
      this.runCallback();
      this.detectFinish();
    }
  }

  runCallback() {
    if (this.pathname === null) {
      this.loading = false;
    } else {
      if (
        this.callback &&
        !(this.request - this.response) &&
        this.request &&
        this.response &&
        this.loading === true
      ) {
        this.loading = false;
        this.callback.cb(false);
      }
      if (
        this.callback &&
        this.request - this.response &&
        this.request &&
        this.response &&
        this.loading === false
      ) {
        this.loading = true;
        this.callback.cb(true);
      }
    }
  }

  addCallback(cb, value) {
    this.callback = { cb: cb, value: value };
  }
  getLoading() {
    return this.loading;
  }
  detectFinish() {
    let myInterval = setInterval(() => {
      if (this.finishCount < this.request) {
        this.finishCount = this.request;
      } else {
        if (this.finishCount === this.response) {
          this.pathname = null;

          clearInterval(myInterval);
        }
      }
    }, 1);
  }
};

export const dataLoading = new loading();
class ReduceResponse {
  constructor() {
    this.urls = [];
  }
  addPath(path) {
    this.urls.push(path);
  }
  getUrls() {
    return this.urls;
  }
}

let reduceResponse = new ReduceResponse();

reduceResponse.addPath("/api/integrations/facebook-server/send-message");
reduceResponse.addPath("/api/ticket-management/ticket/send-message");

export const InterceptersRequest = axios.interceptors.request.use((config) => {
  dataLoading.requestCount();
  // console.log("dataLoading", config)
  return config;
});

export const InterceptersResponse = axios.interceptors.response.use(
  (response) => {
    dataLoading.responseCount();
    const data = response?.data?.data;

    function messageF(message) {
      return reduceResponse
        .getUrls()
        .find((url) => response.config.url.includes(url))
        ? null
        : toast.success(message, {
            position: "bottom-left",
            duration: 2000,
          });
    }

    data?.message ? messageF(data.message) : null;

    return response;
  },
  (error) => {
    dataLoading.responseCount();

    const errorData = error?.response?.data?.data?.error;
    const errors = [];
    switch (error.response.status) {
      case 400:
        Object.keys(errorData).map((name) => {
          errors.push(errorData[name]);
        });
        return errors.map((error) => {
          if (typeof error === "string") {
            toast.error(error, {
              position: "top-center",
              duration: 2000,
            });
          }
        });
      case 422:
        Object.values(errorData).map((name) => errors.push(name));
        return errors.map((error) => {
          toast.error(error, {
            position: "top-center",
            duration: 2000,
          });
        });
      case 500:
        return toast.error("Server Error!", {
          position: "top-center",
          duration: 2000,
        });
      case 413:
        Object.values(errorData).map((name) => errors.push(name));
        return errors.map((error) => {
          toast.error(error, {
            position: "top-center",
            duration: 2000,
          });
        });
      case 403:
        if (error?.response?.config?.url == "api/auth/login") {
          window.location.href =
            "/verify/?email=" + JSON.parse(error.response.config.data).email;
        }
        Object.values(errorData).map((name) => errors.push(name));
        return errors.map((error) => {
          toast.error(error, {
            position: "top-center",
            duration: 2000,
          });
        });
      case 401:
        if (window.location.pathname !== "/login") {
          window.location.href =
            "/login" + "?redirect=" + window.location.pathname;
        }
        localStorage.removeItem("permissions");
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("userData");

        return error?.response?.data?.data?.map((item) =>
          toast.error(item, {
            position: "top-center",
            duration: 2000,
          })
        );
      case 429:
        // TODO : 429 sayfası yapılıp oraya yönlendirilmeli
        window.location.href = "/404";
      // default:
      //   window.location.href = "/404";
    }
    throw error;
  }
);
