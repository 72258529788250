const initialState = {
  customerId: null,
  tickets: [],
  totalpage: null,
  filterparams: {},
  currentpage: 1,
  assignusers: [],
  sources: [],
  params: {
    offset: 1,
    limit: 7,
    search: null,
  },
  delete: {
    id: null,
    status: false,
  },
  addTicketContact: null,
  addTicketContactProps: false,
  favorite_reason_codes: [],
  favorite_categories: [],
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TICKETS":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_PARAMS":
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload,
        },
      };
    case "DELETE_TICKET":
      return {
        ...state,
        delete: action.payload,
      };
    case "DELETED_SUCCESS":
      return {
        ...state,
        delete: {
          status: false,
          id: null,
        },
      };
    case "CANCEL_DELETE":
      return {
        ...state,
        delete: {
          status: false,
          id: null,
        },
      };
    case "SET_CUSTOMER_ID":
      return {
        ...state,
        customerId: action.payload,
      };
    case "ADD_TICKET_CONTACT":
      return {
        ...state,
        addTicketContact: action.data,
      };
    case "ADD_TICKET_CONTACT_PROPS":
      return {
        ...state,
        addTicketContactProps: !state.addTicketContactProps,
      };
    case "SET_FAVORITE_TICKET_REASON_CODES":
      return { ...state, favorite_reason_codes: action.payload };
    case "SET_FAVORITE_TICKET_CATEGORIES":
      return { ...state, favorite_categories: action.payload };
    default:
      return { ...state };
  }
};
export default users;
