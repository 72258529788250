const initialState = {
  addDealContact: null,
  addDealContactProps: false,
  addDealCompany: null,
  addDealCompanyProps: false,
  selectedCurrency: null,
};

const deals = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_DEAL_CONTACT":
      return {
        ...state,
        addDealContact: action.data,
      };
    case "ADD_DEAL_CONTACT_PROPS":
      return {
        ...state,
        addDealContactProps: action.data,
      };
    case "ADD_DEAL_COMPANY":
      return {
        ...state,
        addDealCompany: action.data,
      };
    case "ADD_DEAL_COMPANY_PROPS":
      return {
        ...state,
        addDealCompanyProps: action.data,
      };
    case "SELECTED_CURRENCY":
      return {
        ...state,
        selectedCurrency: action.data,
      };

    default:
      return state;
  }
};

export default deals;
