const initialState = {
    allData: [],
    data: [],
    totalPage: 1,
    params: {},
    selectedUser: null,
    addedUser: null,
    filterItems: [],
    userLogData: [],
    totalLogPage: 1,
    isDeleteModalOpen: {
      status: false,
      id: null,
    },
  };
  
  const smsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_FILTER_ITEMS":
        return { ...state, filterItems: action.data };
  
      case "GET_SMS_ACCOUNTS":
        return { ...state, allData: action.allData, totalPage: action.totalPages };
  
      case "GET_USER":
        return { ...state, selectedUser: action.data };
      case "ADD_USER":
        return { ...state, addedUser: action.data };
      case "DELETE_SMS_TEMPLATE":
        let newState = state.allData.filter((c) => c.userID !== action.data);
        return {
          ...state,
          allData: newState,
        };
      case "USER_LOG_DATA":
        return {
          ...state,
          userLogData: action.data.data,
          totalLogPage: action.totalLogPage,
        };
      case "CLOSE_SMS_MODAL":
        return {
          ...state,
          isDeleteModalOpen: {
            status: action.data,
            id: action.id,
          },
        };
  
      default:
        return { ...state };
    }
  };
  export default smsReducer;