const initialState = {
  deleteModal: {
    id: null,
    status: false,
  },
  outboundModal: {
    appointment_id: null,
    id: null,
    status: false,
    outbound: null,
    type: null,
  },
  refresh: false,
};

const appointments = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_DELETE_APPOINTMENT":
      return {
        ...state,
        deleteModal: {
          id: action.id,
          status: action.status,
        },
      };
    case "TOGGLE_OUTBOUND_APPOINTMENT":
      return {
        ...state,
        outboundModal: {
          appointment_id: action.payload.appointment_id,
          id: action.payload.id,
          status: action.payload.status,
          type: action.payload.type,
          outbound: action.payload.outbound,
        },
      };
    case "REFRESH_APPOINTMENTS":
      return {
        ...state,
        refresh: !state.refresh,
      };
    default:
      return { ...state };
  }
};

export default appointments;
