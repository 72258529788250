const initialState = {
  allData: [],
  data: [],
  totalPage: 1,
  params: {},
  selectedProject: null,
  filterItems: [],
  addedProject: null,
  totalLogPage: 1,
  isDeleteModalOpens: {
    status: false,
    id: null,
  },
  isContactModalOpen: false,
  selectedTab: {
    tab: "1",
    modal: false,
  },
  addContact: null,
  addModalProps: false,
};

const customers = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SELECTED_TAB":
      return { ...state, selectedTab: action.data };
    case "GET_FILTER_ITEMS":
      return { ...state, filterItems: action.data };
    case "GET_ALL_CUSTOMER_DATA":
      return {
        ...state,
        allData: action.data,
        total: action.total,
        totalPage: action.totalPages,
      };
    case "GET_MY_CUSTOMER_DATA":
      return {
        ...state,
        allData: action.data,
        total: action.total,
        totalPage: action.totalPages,
      };

    case "GET_DATA":
      return {
        ...state,
        data: action.data,
        total: action.total,
        params: action.params,
      };

    // case "GET_SMS_ROLES":
    //   console.log("rolesms", { ...state, allData: action.data });

    //   return {
    //     ...state,
    //     allData: action.data,
    //     // totalPage:action.totalPage
    //   };
    case "GET_CUSTOMER_DATA":
      return { ...state, selectedContact: action.data };
    // case "ADD_COMPANY":
    //   console.log("addingdatatoproject", {
    //     ...state,
    //     addedContact: action.data,
    //   });
    //   return { ...state, addedProject: action.data };
    case "DELETE_CUSTOMER_TEMPLATE":
      let newState = state.allData.filter((c) => c.customer_id !== action.data);

      return {
        ...state,
        allData: newState,
      };
    case "TOGGLE_DELETE_CONTACT_MODAL":
      return {
        ...state,
        isDeleteModalOpens: action.data,
      };

    // case "DELETE_MODAL_OPENS":
    //   return {
    //     ...state,
    //     isDeleteModalOpens: {
    //       status: action.data,
    //       id: action.id,
    //     },
    //   };
    case "CLOSE_MODALS":
      return {
        ...state,
        isDeleteModalOpens: {
          status: action.data,
          id: action.id,
        },
      };
    case "TOGGLE_ADD_CONTACT_MODAL":
      return { ...state, isContactModalOpen: !state.isContactModalOpen };

    case "ADD_CONTACT":
      return { ...state, addContact: action.data };
    case "ADD_MODAL_CONTACT_PROPS":
      return { ...state, addModalProps: action.data };

    default:
      return { ...state };
  }
};
export default customers;
