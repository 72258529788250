// !! Bismillahirrahmanirrahim بِسْــــــــــــــــــمِ اﷲِالرَّحْمَنِ اارَّحِيم **

// ** React Imports
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/storeConfig/store";

// ** Intl, CASL & ThemeColors Context
import ability from "./configs/acl/ability";
import { AbilityContext } from "./utility/context/Can";
import { ThemeContext } from "./utility/context/ThemeColors";
import { IntlProviderWrapper } from "./utility/context/Internationalization";
import "@ckeditor/ckeditor5-build-classic/build/translations/tr";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** Fake Database
import "./@fake-db";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

//animate.css
import "animate.css";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";

// ** Intercepters
import { InterceptersRequest, InterceptersResponse } from "./interceptors";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

// ** Utils
import { Toaster } from "react-hot-toast";
import { Notifications } from "react-push-notification";

// ** PostHog
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

ReactDOM.render(
  <Provider store={store}>
    {/* <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    > */}
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <IntlProviderWrapper>
            <LazyApp />
            <Notifications />
            <Toaster
              position="bottom-left"
              toastOptions={{
                style: {
                  background: "#363636",
                  color: "#fff",
                },
              }}
            />
          </IntlProviderWrapper>
        </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
    {/* </PostHogProvider> */}
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
