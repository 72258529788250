const initialState = {
  data: [],
  totalRows: 0,
  perPage: 10,
  widgetCodeModal: {
    id: null,
    file_key: null,
    isOpen: false,
    lang: "en",
  },
  widgetDeleteModal: {
    id: null,
    isOpen: false,
  },
  widgetPreviewModal: {
    id: null,
    file_key: null,
    isOpen: false,
    lang: "en",
  },
};

const chatWidget = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_DATA":
      return {
        ...state,
        data: action.payload.data,
        totalRows: action.payload.totalRows,
        perPage: action.payload.perPage,
      };

    case "OPEN_WIDGET_CODE_MODAL":
      return {
        ...state,
        widgetCodeModal: {
          id: action.payload.id,
          file_key: action.payload.file_key,
          isOpen: true,
          lang: action.payload.lang ? action.payload.lang : "en",
          icon: action.payload.icon ? action.payload.icon : "",
          color: action.payload.color ? action.payload.color : "#008080",
          platform: action.payload.platform ? action.payload.platform : "1",
          chat_height: action.payload.chat_height ? action.payload.chat_height : 100,
        },
      };

    case "CLOSE_WIDGET_CODE_MODAL":
      return {
        ...state,
        widgetCodeModal: {
          id: null,
          file_key: null,
          isOpen: false,
        },
      };

    case "OPEN_WIDGET_DELETE_MODAL":
      return {
        ...state,
        widgetDeleteModal: {
          id: action.payload.id,
          isOpen: true,
        },
      };

    case "CLOSE_WIDGET_DELETE_MODAL":
      return {
        ...state,
        widgetDeleteModal: {
          id: null,
          isOpen: false,
        },
      };

    case "DELETE_WIDGET":
      return {
        ...state,
        widgetDeleteModal: {
          id: null,
          isOpen: false,
        },
        data:
          state.data.length > 0
            ? state.data.filter((item) => item.id !== action.payload.id)
            : [],
      };

    case "CHAT_WIDGET_PREVIEW_MODAL":
      return {
        ...state,
        widgetPreviewModal: {
          id: action.data.id,
          file_key: action.data.file_key,
          isOpen: action.isOpen,
          lang: action.data.lang,
        },
      };

    default:
      return { ...state };
  }
};
export default chatWidget;
