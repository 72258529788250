const initialState = {
  allData: [],
  data: [],
  params: {},
  totalPage: 1,
  deleteModal: {
    status: false,
    id: null,
  },
};

const emailTemplate = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_EMAIL_TEMPLATES":
      return {
        ...state,
        allData: action.data,
        totalPage: action.totalPage,
      };
    case "TOGGLE_EMAIL_DELETE_MODAL":
      return {
        ...state,
        deleteModal: {
          status: !state.deleteModal.status,
          id: action.payload.id,
        },
      };
    case "DELETE_EMAIL_TEMPLATE":
      return {
        ...state,
        allData: state.allData.filter(
          (item) => item.template_id !== action.payload.id
        ),
      };

    default:
      return {
        ...state,
      };
  }
};

export default emailTemplate;
