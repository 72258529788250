import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faBan,
  faHandshake,
  faUser,
  faChartBar,
  faCircle,
  faUtensils,
  faChalkboardUser,
  faMugSaucer,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";

// **  Initial State
const initialState = {
  userData: {},
  isUnAuth: true,
  loading: false,
  customization: {},
  userStatus: [
    {
      statu_id: 1,
      name: "Available",
      is_available: 1,
      icon: { icon: faCircle, color: "#0BDA51" },
    },
    {
      statu_id: 2,
      name: "After Call",
      is_available: 0,
      icon: { icon: faPhoneAlt, color: "" },
    },
    {
      statu_id: 3,
      name: "Busy",
      is_available: 0,
      icon: { icon: faBan, color: "#e74c3c" },
    },
    {
      statu_id: 4,
      name: "Break",
      is_available: 0,
      icon: { icon: faMugSaucer, color: "" },
    },
    {
      statu_id: 5,
      name: "Lunch",
      is_available: 0,
      icon: { icon: faUtensils, color: "" },
    },
    {
      statu_id: 6,
      name: "Meeting",
      is_available: 0,
      icon: { icon: faHandshake, color: "" },
    },
    {
      statu_id: 7,
      name: "Offline",
      is_available: 0,
      icon: { icon: faUser, color: "" },
    },
    {
      statu_id: 8,
      name: "Other",
      is_available: 0,
      icon: { icon: faChartBar, color: "" },
    },
    {
      statu_id: 9,
      name: "Training",
      is_available: 0,
      icon: { icon: faChalkboardUser, color: "" },
    },
    {
      statu_id: 10,
      name: "Backoffice",
      is_available: 0,
      icon: { icon: faBuilding, color: "" },
    },
  ],
  status: 1,
  checkIntegrations: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        userData: {
          ...action.data,
          profile_image: action.data.pp,
        },
        [action.config.storageTokenKeyName]: action.config.storageTokenKeyName,
        [action.config.storageRefreshTokenKeyName]:
          action.config.storageRefreshTokenKeyName,
      };

    case "LOGOUT":
      const obj = { ...action };
      delete obj.type;
      return { ...state, userData: {}, ...obj };

    case "CHECK_AUTH":
      return {
        ...state,
        isUnAuth: action.data,
      };
    case "UPDATE_STATUS":
      return {
        ...state,
        status: action.data,
      };
    case "CUSTOMIZATION":
      return {
        ...state,
        customization: action.data,
      };

    case "UI_LOADING":
      return {
        ...state,
        loading: action.data,
      };
    case "CHANGE_PROFILE_PHOTO":
      return {
        ...state,
        userData: {
          ...state.userData,
          pp: action.data,
        },
      };
    case "CHANGE_USER_NAME":
      return {
        ...state,
        userData: {
          ...state.userData,
          ns: action.data,
        },
      };
    case "SET_CHANGE_ACTIVE_CHANNELS":
      return {
        ...state,
        userData: {
          ...state.userData,
          ucid: action.data,
        },
      };

    case "SET_CHECK_INTEGRATIONS":
      return {
        ...state,
        checkIntegrations: action.data,
      };

    default:
      return state;
  }
};

export default authReducer;
