// ** Initial State
const initialState = {
  modalState: false,
  events: [],
  calendarType: 1,
  selectedEvent: {},
  selectedCalendars: ["Free", "Busy", "Calls", "Meetings"],
  contactEventId: null,
  wizardModal: {
    isOpen: false,
    type: null,
  },
};

const calenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_MODAL_CALENDAR":
      return { ...state, modalState: !state.modalState };
    case "TOGGLE_MODAL_CLOSE_CALENDAR":
      return { ...state, modalState: false };
    case "FETCH_EVENTS":
      return { ...state, events: action.events };
    case "UPDATE_TYPE":
      return { ...state, calendarType: action.calendarType };
    case "ADD_EVENT":
      return { ...state };
    case "REMOVE_EVENT":
      return { ...state };
    case "UPDATE_EVENT":
      return { ...state };
    case "UPDATE_FILTERS":
      // ** Updates Filters based on action filter
      const filterIndex = state.selectedCalendars.findIndex(
        (i) => i === action.filter
      );
      if (state.selectedCalendars.includes(action.filter)) {
        state.selectedCalendars.splice(filterIndex, 1);
      } else {
        state.selectedCalendars.push(action.filter);
      }
      if (state.selectedCalendars.length === 0) {
        state.events.length = 0;
      }
      return { ...state };
    case "UPDATE_ALL_FILTERS":
      // ** Updates All Filters based on action value
      const value = action.value;
      let selected = [];
      if (value === true) {
        selected = ["Free", "Busy", "Calls", "Meetings"];
      } else {
        selected = [];
      }
      return { ...state, selectedCalendars: selected };
    case "SELECT_EVENT":
      return { ...state, selectedEvent: action.event };
    case "CONTACT_EVENT_ID":
      return { ...state, contactEventId: action.contactEventId };

    case "WIZARD_MODAL":
      return {
        ...state,
        wizardModal: action.data,
      };

    default:
      return state;
  }
};

export default calenderReducer;
