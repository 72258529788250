// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import conversation from "./conversation";
import navbar from "./navbar";
import layout from "./layout";
import notifications from "./notifications";
import menu from "./sidebarMenu";
import chat from "@src/views/apps/chat/store/reducer";
import email from "@src/views/apps/email/store/reducer";
import todo from "@src/views/apps/todo/store/reducer";
import users from "../../views/pages/users/store/reducers";

import invoice from "@src/views/apps/invoice/store/reducer";
import calendar from "@src/views/apps/calendar/store/reducer";
import ecommerce from "@src/views/apps/ecommerce/store/reducer";
import dataTables from "@src/views/tables/data-tables/store/reducer";
import emailTemplate from "../../../src/views/pages/email/store/reducers";
import smstemplateReducer from "../../../src/views/pages/smstemplate/store/reducers/index";

import readyAnswer from "../../../src/views/pages/readyanswer/store/reducers";
import reasonCode from "../../../src/views/pages/reasoncode/store/reducers";
import ticketReasonCode from "../../../src/views/pages/ticketreasoncode/store/reducers";

import autoAssign from "../../views/pages/autoAssign/store/reducers";
import roleReducer from "../../views/pages/rolemanagement/store/reducers";
import smsReducer from "../../views/pages/smsAccounts/store/reducers";
import phoneReducer from "../../views/pages/phoneAccounts/store/reducers";
import chatWidget from "../../views/chat-widget/store/reducers";
import videoCall from "../../views/video-chat-widget/store/reducers";

import userGroups from "../../views/pages/userGroups/store/reducers";
import ticketcategory from "../../views/pages/ticketcategory/store/reducers";

import customers from "../../views/customerManagement/store/Reducers";
import tagManagement from "../../views/tagManagement/store/reducer";
import projectReducer from "../../views/pages/projects/store/reducers";

import companies from "../../views/companies/store/Reducers";
import mailManagement from "../../views/pages/mailManagement/store/reducers";
import facebookReducer from "../../views/social/facebook/store/reducers";
import whatsapp from "../../views/social/whatsapp/store/reducers";
import { importData } from "../../views/pages/importData/store/reducer";
import tickets from "../../views/pages/tickets/store/reducers";
import form from "../../../src/views/pages/form/store/reducers";

import todolist from "../../@core/components/customizer/todo/store/reducers";
import webphone from "../../@core/components/customizer/webphone/store/reducers";
import deals from "../../views/pages/deal/store/reducers";
import appointments from "../../views/pages/appointments/store/reducers";
import workplaceChat from "../../@core/components/customizer/workplace-chat/store/reducers";

const rootReducer = combineReducers({
  auth,
  conversation,
  menu,
  form,
  todo,
  email,
  chat,
  users,
  navbar,
  layout,
  notifications,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  whatsapp,
  tickets,
  emailTemplate,
  autoAssign,
  userGroups,
  ticketcategory,
  tagManagement,
  customers,
  companies,
  mailManagement,
  facebookReducer,
  roleReducer,
  projectReducer,
  readyAnswer,
  reasonCode,
  ticketReasonCode,
  chatWidget,
  smstemplateReducer,
  videoCall,
  importData,
  smsReducer,
  phoneReducer,
  todolist,
  webphone,
  deals,
  appointments,
  workplaceChat
});

export default rootReducer;
