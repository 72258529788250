const initialState = {
  chatList: [],
  activeChat: {},
  activeMessages: [],
  activeActivity: [],
  activeContact: {},
  activeContactData: {},
  activeContactStaticData: {},
  offlineChatList: [],
  transferList: [],
  saveContact: false,
  addChatCompany: null,
  activeEmailContent: [],
  addModalProps: false,
  agentAssistData: [],
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONVERSATION_LIST":
      return {
        ...state,
        chatList: action.data.sort((a, b) => {
          return new Date(b.message.createdAt) - new Date(a.message.createdAt);
        }),
      };
    case "NEW_CONVERSATION":
      const isExist = state.chatList.includes(
        state.chatList.find((item) => item.roomId === action.data.roomId)
      );
      if (isExist) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          chatList: [action.data, ...state.chatList].sort((a, b) => {
            return (
              new Date(b.message.createdAt) - new Date(a.message.createdAt)
            );
          }),
        };
      }
    case "SET_ACTIVE_CONVERSATION":
      return {
        ...state,
        activeChat: action.data,
        chatList: state.chatList.map((item) => {
          if (item.roomId === action.data.roomId) {
            item.isNotReadCount = 0;
          }
          return item;
        }),
      };
    case "SET_ACTIVE_OFFLINE_CONVERSATION":
      return {
        ...state,
        activeChat: action.data,
      };
    case "SET_ACTIVE_MESSAGES":
      return {
        ...state,
        activeMessages: [...action.data],
      };
    case "DELETE_MESSAGE":
      return {
        ...state,
        activeMessages: state.activeMessages.filter(
          (item) => item.messageId !== action.messageId
        ),
      };
    case "ADD_NEW_MESSAGE_TO_ACTIVE_CHAT":
      return {
        ...state,
        activeMessages: [...state.activeMessages, action.data],
      };
    case "REMOVE_CLOSED_CONVERSATION":
      return {
        ...state,
        chatList: state.chatList.filter(
          (item) => item.roomId !== action.roomId
        ),
      };
    case "SET_ACTIVE_ACTIVITY":
      return {
        ...state,
        activeActivity: [...action.data],
      };
    case "RESORT_CONVERSATIONS":
      return {
        ...state,
        chatList: state.chatList.sort((a, b) => {
          return new Date(b.message.createdAt) - new Date(a.message.createdAt);
        }),
      };
    case "SET_LAST_MESSAGE_UPDATE":
      return {
        ...state,
        chatList: state.chatList.map((item) => {
          if (item.roomId === action.data.roomId) {
            item.message = {
              ...item.message,
              message: action.data.message,
              createdAt: action.data.createdAt,
              type: action.data.type,
            };
            item.isNotReadCount += action.data.sender === 1 ? 0 : 1;
          }
          return item;
        }),
      };
    case "SET_ACTIVE_CONTACT":
      return {
        ...state,
        activeContact: action.data,
      };
    case "SET_ACTIVE_CONTACT_DATA":
      return {
        ...state,
        activeContactData: action.data,
      };
    case "SET_ACTIVE_CONTACT_STATIC_DATA":
      return {
        ...state,
        activeContactStaticData: action.data,
      };
    case "SAVE_ACTIVE_CONTACT":
      return {
        ...state,
        saveContact: action.data,
      };
    case "RESET_READ_INFO":
      return {
        ...state,
        chatList: state.chatList.map((item) => {
          if (item.roomId === action.roomId) {
            item.isNotReadCount = 0;
          }
          return item;
        }),
      };
    case "SET_READ_INFO":
      return {
        ...state,
        activeMessages: state?.activeMessages.map((item) => {
          item.originalRead = 1;
          return item;
        }),
      };
    case "SET_OFFLINE_CONVERSATION_LIST":
      return {
        ...state,
        offlineChatList: action.data,
      };

    case "ADD_CHAT_COMPANY":
      return {
        ...state,
        addChatCompany: action.data,
      };
    case "SET_EMAIL_CONTENT":
      return {
        ...state,
        activeEmailContent: action.data,
      };
    case "ADD_CHAT_COMPANY_PROPS":
      return { ...state, addModalProps: action.data };
    case "ADD_TRANSFER_LIST":
      return {
        ...state,
        transferList: [
          ...state.transferList.filter(
            (item) => item.roomId !== action.data.roomId
          ),
          { ...action.data, createdAt: new Date() },
        ],
      };
    case "REMOVE_TRANSFER_LIST":
      return {
        ...state,
        transferList: state.transferList.filter(
          (item) => item.roomId !== action.roomId
        ),
      };
    case "UPDATE_NEW_ID_BEFORE_MERGE":
      return {
        ...state,
        chatList: state.chatList.map((item) => {
          if (item.customerId == action.oldId) {
            item.customerId = action.newId;
          }
          return item;
        }),
        activeChat: {
          ...state.activeChat,
          customerId:
            state.activeChat.customerId == action.oldId
              ? action.newId
              : state.activeChat.customerId,
        },
        activeContact: {
          ...state.activeContact,
          customer_id:
            state.activeContact.customer_id == action.oldId
              ? action.newId
              : state.activeContact.customer_id,
        },
      };

    case "SET_AGENT_ASSIST_DATA":
      return {
        ...state,
        agentAssistData: action.data,
      };

    default:
      return { ...state };
  }
};

export default chatReducer;
