const initialState = {
  userChats: [],
  groupChats: []
};

export default function workplaceChat(state = initialState, action) {
  switch (action.type) {
    case "WORKPLACE_SET_USER_CHATS":
      return { ...state, userChats: action.data };
    case "WORKPLACE_SET_GROUP_CHATS":
      return { ...state, groupChats: action.data };
    default:
      return state;
  }
}
