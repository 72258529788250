const initialState = {
  startCall: {
    number: "",
    status: false,
  },
  call: {
    number: "",
    originator: "",
    status: "",
    connect: "",
  },
  activeCall: {
    hold: false,
    mute: false,
    transfer: false,
    attendedTransfer: false,
    disabled: false,
    refer: "",
  },
  inCall: false,
  webphoneMuted: false,
  spechySocketConnected: false,
};

const webphone = (state = initialState, action) => {
  switch (action.type) {
    case "START_CALL":
      return {
        ...state,
        startCall: {
          ...state.startCall,
          number: action.number,
          status: action.status,
        },
      };
    case "RESET_CALL":
      return {
        ...state,
        startCall: {
          number: "",
          status: false,
        },
      };
    case "END_CALL":
      return {
        ...state,
        startCall: {
          number: "",
          status: false,
        },
        call: {
          ...state.call,
          number: "",
          originator: "",
          status: "",
          connect: "",
        },
        activeCall: {
          ...state.activeCall,
          hold: false,
          mute: false,
          transfer: false,
          attendedTransfer: false,
          disabled: false,
          refer: "",
        },
      };
    case "SET_ACTIVE_CALL":
      return {
        ...state,
        activeCall: {
          ...state.activeCall,
          hold: action.hold,
          mute: action.mute,
          transfer: action.transfer,
          attendedTransfer: action.attendedTransfer,
          disabled: action.disabled,
          refer: action.refer,
        },
      };
    case "SET_CALL":
      return {
        ...state,
        call: {
          ...state.call,
          number: action.number,
          originator: action.originator,
          status: action.status,
          connect: action.connect,
        },
      };
    case "UPDATE_IN_CALL":
      return {
        ...state,
        inCall: action.data,
      };
    case "SET_WEBPHONE_MUTED":
      return {
        ...state,
        webphoneMuted: action.data,
      };
    case "UPDATE_SPECHY_SOCKET_CONNECTED":
      return {
        ...state,
        spechySocketConnected: action.data,
      };
    default:
      return {
        ...state,
      };
  }
};

export default webphone;
